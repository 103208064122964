<template>
  <van-config-provider :theme-vars="themeVars">
    <div class="ticket-warp">
      <div class="content my-3">
        <div class="scetion pt-3">
          <div class="label mb-2">刷卡支付</div>
          <van-field type="text" maxlength="11" class="border-cell align-items-center" :border="false"
            v-model="state.title" placeholder="请输入付款说明" />
          <van-field type="number" maxlength="11" class="border-cell align-items-center" :border="false"
            v-model="state.amount" placeholder="请输入付款金额" />
        </div>
        <van-action-bar>
          <van-button type="primary" class="mx-2" block :text="$t('confirm_pay')" @click="handlePurchase" />
        </van-action-bar>
      </div>
    </div>
  </van-config-provider>
</template>

<script>
import wx from "weixin-js-sdk";
import { Toast, ActionBar, ActionBarIcon, ActionBarButton, Rate, Popup, Field } from 'vant';
import { purchase } from "@/api/swipe.service";
import { onMounted, reactive, ref } from "@vue/runtime-core";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { signaturePack } from "@/api/wechat.service";
import { createDeviceDetector } from "next-vue-device-detector";
import { stripTags } from "@/utils/util";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { toRaw } from "vue";
export default {
  props: {
    id: {
      type: String,
      require: true,
    }
  },
  components: {
    [ActionBar.name]: ActionBar,
    [ActionBarIcon.name]: ActionBarIcon,
    [ActionBarButton.name]: ActionBarButton,
    [Rate.name]: Rate,
    [Popup.name]: Popup,
    [Field.name]: Field,
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const store = useStore();
    const device = createDeviceDetector();
    const { wechat } = device;

    const route = useRoute();
    const router = useRouter();
    const show = ref(false);
    const autosubmit = ref(false);  // 微信环境下，微信授权后自动提交购买
    const logo = require(process.env.VUE_APP_LOGO_DARK);
    const ticket = reactive({ id: 0, price: 0, scenic_spot: { score: 0, description: "", preview_list: [] } });
    const preauth = JSON.parse(window.sessionStorage.getItem("preauth"));
    const state = reactive({ title: "技术服务费", amount: "" });

    const currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const icons = {
      calendar: require("@/assets/images/icons/calendar.svg"),
      timer: require("@/assets/images/icons/timer.svg"),
      checkticket: require("@/assets/images/icons/checkticket.svg"),
      wechatpay: require("@/assets/images/icons/wechatpay.svg"),
      alipay: require("@/assets/images/icons/alipay.svg"),
      logo: require("@/assets/images/icons/logo.svg"),
      mobile: require("@/assets/images/icons/mobile.svg"),
      shield: require("@/assets/images/icons/shield.svg"),
      paypal: require("@/assets/images/icons/paypal.png"),
    };

    const themeVars = {
      // tabsBottomBarColor: "#222",
      // buttonPrimaryBackgroundColor: "#222",
      // buttonPrimaryBorderColor: "#222",
      cardThumbSize: "66px",
    };

    const signaturePage = async () => {
      try {
        const url = /(Android)/i.test(navigator.userAgent)
          ? location.href.split("#")[0]
          : window.entryUrl;
        const { result } = await signaturePack(url);
        wx.config({
          appId: result.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ["hideOptionMenu", "closeWindow", "chooseWXPay"],
        });
        wx.ready(() => {
          wx.hideOptionMenu();
          if (autosubmit.value) {
            handlePurchase();
          }
        });
        wx.error((res) => {
          Toast.fail(res.errMsg);
        });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast.fail(msg ? msg : t('front_end_error2'));
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      if (wechat) {
        signaturePage();
        try {
          const swape = JSON.parse(window.sessionStorage.getItem("swape"));
          state.title = swape.title;
          state.amount = swape.amount;
        } catch (error) {
          console.log(error);
        }
        if (!state.amount) {
          state.amount = parseInt(Math.random() * 10000)
        }
        autosubmit.value = route.query?.autosubmit;
      } else {
        window.location.hfre = "/";
      }
    });

    const handlePurchase = async () => {
      // 微信环境先获取 openid，用于支付，授权成功后会跳回本页面继续自动支付
      if (wechat && !preauth) {
        const url = window.location.href.indexOf("?") > 0 ? `${window.location.href}&autosubmit=1` : `${window.location.href}?autosubmit=1`;
        window.sessionStorage.setItem("swape", JSON.stringify(toRaw(state)));
        window.sessionStorage.setItem("login_redirect_url", url);
        window.location.href = "/wechat-login";
        return;
      }

      try {
        const data = {
          title: state.title,
          amount: state.amount,
          openid: preauth?.openid,
        };
        const { result } = await purchase(data);
        window.sessionStorage.removeItem("swape");
        window.sessionStorage.removeItem("preauth");
        window.sessionStorage.removeItem("login_redirect_url");

        // 微信公众号JSSDK支付
        wx.chooseWXPay({
          timestamp: result.timeStamp,
          nonceStr: result.nonceStr,
          package: result.package,
          signType: result.signType,
          paySign: result.paySign,
          success: () => {
            Toast.success({
              message: t('payment_successful'),
              forbidClick: true,
              overlay: true,
            });
            router.push({ name: "Home", params: { trade_no: result.trade_no } });
          },
          error: (res) => {
            Toast.fail(res.errMsg ? res.errMsg : t('payment_failed'));
          },
          cancel: () => {
            Toast.fail(t('payment_cancelled'));
          },
        });
        router.push({ name: "Home", params: { trade_no: result.trade_no } });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        if (!msg) console.log(error);
        const message = msg ? msg : t('front_end_error');
        Toast.fail(message);
      }
    };

    return { show, logo, icons, ticket, state, wechat, themeVars, currentUser, stripTags, handlePurchase }
  }
}
</script>

<style lang="less" scoped>
.ticket-warp {
  width: 100vw;
  padding: 0;
  vertical-align: middle;
  background-color: #fff;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;

  .content {
    background-color: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 0 20px;
    padding-bottom: 80px;

    .van-button {
      border-radius: 20px;
    }

    .scetion {

      .label {
        color: #969799;
      }

      .value {
        margin-left: 20px;
        margin-top: 10px;
      }
    }

    .border-cell {
      border: 1px solid #e5e5e5;
      border-radius: 20px;
      margin-bottom: 20px;
    }

    .van-action-bar {
      padding: 20px 0;
      background-color: #fff;
    }

    .priceinfo {
      width: 80%;
      margin-left: 10px;

      .price {
        font-size: 32px;
        color: #fa607d;

        small {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
