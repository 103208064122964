import ApiService from "./api.service";

export function purchase(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/swipe/pay", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
